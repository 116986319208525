const initialState = {
    rate: [],
    report: [],
    month: [],
    week: [],
    day: [],
    reportAdmin: [],
    ranking: [],
    reportHistory: [],
    accounts: [],
    export: [],
    isUpdated: false,
    billings: []
}
const BillingReducer = function (state = initialState, action) {
    switch (action.type) {
            case 'SET_REPORT_TYPE': {

            return {
                ...state,
                [action.timeFrame]: action.report,
                isUpdated: true
            }
        }    
        case 'SET_RATE': {

            return {
                ...state,
                rate: action.rate,
                isUpdated: true
            }
        }
             case 'SET_REPORT_HISTORY': {

            return {
                ...state,
                reportHistory: action.reportHistory,
                isUpdated: true
            }
        }
            case 'GET_ACCOUNT': {
      return {
        ...state,
        accounts: action.user
        
      }

        }
             case 'SET_MONTH_REPORT': {
      return {
        ...state,
        reportMonth: action.reportMonth,
        isUpdated: true
      }
    }
            case 'SET_EXPORT': {

            return {
                ...state,
                export: action.export,
                isUpdated: true
            }
        }    
             default: {
            return { ...state }
        }
        case 'GET_ACCOUNT_BILLING': {
            return {
              ...state,
              billings: action.billings
              
            }
              } 
    }
}


export default BillingReducer

