import { combineReducers } from 'redux'
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import RelatorioReducer from './reducers/RelatorioReducer'
import HistoricoReducer from './reducers/HistoricoReducer'
import historicoGeralReducer from './reducers/HistoricoGeralReducer'
import CrudLocReducer from './reducers/CrudLocReducer'
import CrudUserReducer from './reducers/CrudUserReducer'
import SgrlocProcessReducer from './reducers/SgrlocProcessReducer'
import ProfileReducer from './reducers/ProfileReducer'
import RelatorioAdminReducer from './reducers/RelatorioAdminReducer'
import BillingReducer from './reducers/BillingReducer'
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  rentals: CrudLocReducer,
  users: CrudUserReducer,
  relatorio: RelatorioReducer,
  relatorioAdmin: RelatorioAdminReducer,
  historico: HistoricoReducer,
  historicogeral: historicoGeralReducer,
  sgrlocprocess: SgrlocProcessReducer,
  profile: ProfileReducer,
  billing : BillingReducer
  
})

export default rootReducer
