const initialState = {
    restricted: [],
    isUpdated: false,
    created: false
}

const SMSProcessReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'START_CREATION': {
            return { ...state, created: false }
        }
        case 'CREATE_RESTRICTED': {
            return {
                ...state,
                response: action.response,
                created: true
            }
        }
    default: {
      return { ...state }
    }
  }
}

export default SMSProcessReducer
